<template>
  <vs-popup
      class="sick-leave-modal"
      title="Krankmeldung einreichen"
      @close="onClose"
      :active="visible"
  >
    <div class="p-4">
      <vs-row class="mb-4">
        <vs-col vs-w="12">
          <label>📌 Ich melde mich krank für...</label>
          <vs-select v-model="sickLeave.duration" class="w-full">
            <vs-select-item value="today" text="Heute bzw. Rest des Tages" />
            <vs-select-item value="today-update-tomorrow" text="Heute + ggf. noch Morgen, ich gebe morgen ein Update" />
            <vs-select-item value="multiple-days" text="Vermutlich mehrere Tage" />
          </vs-select>
          <vs-input
              v-if="sickLeave.duration === 'multiple-days'"
              v-model="sickLeave.estimatedDays"
              type="number"
              placeholder="Voraussichtliche Dauer in Tagen"
              class="mt-2 w-full"
          />

          <p v-if="sickLeave.estimatedDays == 1" class="text-warning text-sm mt-1">
            Dann kannst du dich auch einfach für heute krank melden.
          </p>
          <p v-if="sickLeave.estimatedDays >= 3" class="text-danger text-sm mt-1">
            Bitte beachte: Ab 3 Krankheitstagen ist eine Krankmeldung (AU) erforderlich.
          </p>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4">
        <vs-col vs-w="12">
          <label>👨‍⚕️ Krankmeldung (AU)?</label>
          <vs-select v-model="sickLeave.auStatus" class="w-full">
            <vs-select-item value="going-to-doctor" text="Ich gehe noch zum Arzt und gebe ein Update" />
            <vs-select-item value="no-doctor" text="Ich werde nicht zum Arzt gehen" />
            <vs-select-item value="paper-au" text="Ich habe eine Krankmeldung auf Papier" />
            <vs-select-item value="digital-au" text="Ich habe eine Krankmeldung digital" />
            <vs-select-item value="submit-later" text="Ich reiche die Krankmeldung nach" />
            <vs-select-item value="unsure" text="Ich kann es aktuell nicht sagen" />
          </vs-select>
        </vs-col>
      </vs-row>

      <vs-row class="mb-4">
        <vs-col vs-w="12">
          <label>📂 Datei-Upload für Krankmeldung (optional)</label>
          <input type="file" @change="onFileUpload" class="w-full" />
        </vs-col>
      </vs-row>

      <vs-row class="mb-4">
        <vs-col vs-w="12">
          <label>📝 Grund (freiwillig, wird dem Team angezeigt)</label>
          <vs-textarea v-model="sickLeave.reason" class="w-full" placeholder="Optionaler Kommentar" />
        </vs-col>
      </vs-row>

      <vs-row class="mb-4">
        <vs-col vs-w="12">
          <label>📝 Sonstige Info für HR/GF: (Wird nicht veröffentlicht)</label>
          <vs-textarea v-model="sickLeave.internal_notice" class="w-full" placeholder="Optionale Notiz an HR/GF." />
        </vs-col>
      </vs-row>

      <vs-row class="mb-4">
        <vs-col vs-w="12">

          <vs-checkbox v-model="sickLeave.newUpdate" class="mb-2">
            Das ist ein neues Update zur bereits mitgeteilten Krankheit/Abwesenheit.
          </vs-checkbox>

          <vs-checkbox v-model="sickLeave.notifyTeam" class="mb-2">
            📢 Ja, ich möchte, dass mein Team per Slack informiert wird.
          </vs-checkbox>
          <vs-checkbox v-if="sickLeave.estimatedDays > 1 || sickLeave.duration === 'today-update-tomorrow'" v-model="sickLeave.absenceSet" required>
            📅 Ja, ich habe meinen Abwesenheitsstatus eingerichtet.
          </vs-checkbox>
          <vs-checkbox v-model="sickLeave.tasksDelegated">
            🔄 Ich habe meine wichtigen Aufgaben an Kollegen übergeben.
          </vs-checkbox>
        </vs-col>
      </vs-row>

      <div class="flex justify-end">
        <vs-button color="danger" type="border" @click="onClose" class="mr-2">Abbrechen</vs-button>
        <vs-button @click="submitSickLeave" :disabled="!formValid">Senden</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import ApiService from "../../api";
export default {
  props: {
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      sickLeave: {
        duration: null,
        estimatedDays: null,
        auStatus: null,
        reason: "",
        notifyTeam: true,
        newUpdate: false,
        absenceSet: false,
        tasksDelegated: false,
        file: null,
      },
    };
  },
  computed: {
    visible() {
      return this.active;
    },
    formValid() {
      return !!this.sickLeave.duration;
    },
  },
  methods: {
    onClose() {
      this.$emit("update:active", false);
    },
    onFileUpload(event) {
      this.sickLeave.file = event.target.files[0];
    },
    async submitSickLeave() {
      const formData = new FormData();
      formData.append("duration", this.sickLeave.duration);
      formData.append("estimated_days", this.sickLeave.estimatedDays || "");
      formData.append("au_status", this.sickLeave.auStatus);
      formData.append("reason", this.sickLeave.reason);
      formData.append("notify_team", this.sickLeave.notifyTeam);
      formData.append("absence_set", this.sickLeave.absenceSet);
      formData.append("tasks_delegated", this.sickLeave.tasksDelegated);
      formData.append("new_update", this.sickLeave.newUpdate);
      if (this.sickLeave.file) {
        formData.append("file", this.sickLeave.file);
      }

      try {
        await ApiService.post("/sick-leave", formData);
        this.$vs.notify({ title: "Erfolgreich", text: "Krankmeldung gespeichert.", color: "success" });
        this.onClose();
      } catch (error) {
        this.$vs.notify({ title: "Fehler", text: "Krankmeldung konnte nicht gespeichert werden.", color: "danger" });
      }
    },
  },
};
</script>

<style>
.sick-leave-modal .vs-popup {
  max-width: 600px;
}
</style>
