<template>
  <vs-popup
      class="speech-to-protocol-modal"
      title="Audio zu Protokoll"
      @close="onClose"
      :active="visible"
  >
    <div class="p-4">
      <!-- Datei-Upload -->
      <vs-row class="mb-4">
        <vs-col vs-w="12">
          <label>🎤 Audio-Datei hochladen (MP3, WAV, M4A)</label>
          <input type="file" @change="onFileUpload" accept="audio/mp3,audio/wav,audio/m4a" class="w-full" />
        </vs-col>
      </vs-row>

      <!-- Status-Anzeige -->
      <vs-row v-if="statusMessage" class="mb-4">
        <vs-col vs-w="12">
          <vs-alert :color="statusColor">{{ statusMessage }}</vs-alert>
        </vs-col>
      </vs-row>

      <!-- Fortschrittsanzeige -->
      <vs-row v-if="isProcessing" class="mb-4">
        <vs-col vs-w="12">
          <vs-progress indeterminate></vs-progress>
        </vs-col>
      </vs-row>

      <!-- Countdown für erneute Anfrage -->
      <vs-row v-if="countdown > 0" class="mb-4">
        <vs-col vs-w="12">
          <vs-alert color="warning">⏳ Nächster Versuch in {{ countdown }} Sekunden...</vs-alert>
        </vs-col>
      </vs-row>

      <!-- Generiertes Protokoll anzeigen -->
      <vs-row v-if="protocol" class="mb-4">
        <vs-col vs-w="12">
          <h3>📑 Generiertes Protokoll:</h3>
          <vs-button @click="copyToClipboard" color="success" icon="content_copy">Kopieren</vs-button>
          <pre class="protocol-box">{{ protocol }}</pre>
        </vs-col>
      </vs-row>

      <!-- Buttons -->
      <div class="flex justify-end">
        <vs-button color="danger" type="border" @click="onClose" class="mr-2">Abbrechen</vs-button>
        <vs-button v-if="!isProcessing" @click="uploadFile" :disabled="!selectedFile">Datei hochladen</vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import ApiService from "../../api";

export default {
  props: {
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      isProcessing: false,
      selectedFile: null,
      jobPublicId: null,
      protocol: null,
      statusMessage: "",
      statusColor: "primary",
      countdown: 0,
      maxAttempts: 10,
      attempts: 0,
    };
  },
  computed: {
    visible() {
      return this.active;
    },
  },
  methods: {
    onClose() {
      this.resetState();
      this.$emit("update:active", false);
    },
    onFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadFile() {
      if (!this.selectedFile) {
        this.statusMessage = "Bitte wähle eine Datei aus!";
        this.statusColor = "danger";
        return;
      }

      this.isProcessing = true;
      this.statusMessage = "📤 Datei wird hochgeladen...";
      this.statusColor = "primary";

      const formData = new FormData();
      formData.append("audio", this.selectedFile);

      try {
        const response = await ApiService.post("/ai/speech-to-protocol", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        this.jobPublicId = response.data.public_id;
        this.statusMessage = "⏳ Transkription gestartet... Warte auf Fertigstellung.";
        this.statusColor = "warning";

        this.attempts = 0;
        this.pollForResult();
      } catch (error) {
        console.error("Fehler beim Hochladen:", error);
        this.statusMessage = "❌ Fehler beim Hochladen.";
        this.statusColor = "danger";
        this.isProcessing = false;
      }
    },
    async pollForResult() {
      if (this.attempts >= this.maxAttempts) {
        this.statusMessage = "❌ Zeitüberschreitung: Transkription nicht abgeschlossen.";
        this.statusColor = "danger";
        this.isProcessing = false;
        return;
      }

      try {
        const response = await ApiService.get(`/ai/speech-to-protocol/${this.jobPublicId}`);

        if (response.data.status === "success") {
          this.protocol = response.data.protocol;
          this.statusMessage = "✅ Protokoll erfolgreich generiert!";
          this.statusColor = "success";
          this.isProcessing = false;
          return;
        }
      } catch (error) {
        if (error.response && error.response.data.message === "Transkription noch nicht abgeschlossen") {
          this.statusMessage = "⏳ Transkription läuft, erneuter Versuch in 5 Sekunden...";
          this.statusColor = "warning";

          this.countdown = 5;
          this.attempts++;

          const countdownInterval = setInterval(() => {
            if (this.countdown > 1) {
              this.countdown--;
            } else {
              clearInterval(countdownInterval);
              this.pollForResult();
            }
          }, 1000);

          return;
        }

        console.error("Fehler beim Abrufen des Protokolls:", error);
        this.statusMessage = "❌ Fehler beim Abrufen des Protokolls.";
        this.statusColor = "danger";
        this.isProcessing = false;
      }
    },
    copyToClipboard() {
      if (!this.protocol) return;

      navigator.clipboard.writeText(this.protocol).then(() => {
        this.$vs.notify({
          title: "Kopiert!",
          text: "Das Protokoll wurde in die Zwischenablage kopiert.",
          color: "success",
        });
      }).catch(err => {
        console.error("Fehler beim Kopieren:", err);
        this.$vs.notify({
          title: "Fehler",
          text: "Konnte den Text nicht kopieren.",
          color: "danger",
        });
      });
    },

    resetState() {
      this.isProcessing = false;
      this.selectedFile = null;
      this.jobPublicId = null;
      this.protocol = null;
      this.statusMessage = "";
      this.countdown = 0;
      this.attempts = 0;
    },
  },
};
</script>

<style scoped>
.speech-to-protocol-modal .vs-popup {
  max-width: 600px;
}

.protocol-box {
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  white-space: pre-wrap;
  max-height: 300px;  /* Begrenzung der Höhe */
  overflow-y: auto;   /* Scrollbar aktivieren, wenn nötig */
}
</style>
